.swiper-wrapper {
  min-height: 400px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 50%;
}
.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #f063b8 !important;
}
