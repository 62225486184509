@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary text-secondary bg-gradient-to-b from-[#F6FAFD] to-[#FFFFFF] bg-no-repeat;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary text-primary;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  a {
    @apply text-secondary font-semibold hover:text-accent-primary_hover transition;
  }

  .btn {
    @apply text-lg h-[60px] px-[30px] rounded-full transition text-white;
  }

  .btn-primary {
    @apply bg-accent-primary hover:bg-accent-primary_hover;
  }

  .btn-secondary {
    @apply bg-accent-secondary hover:bg-accent-secondary_hover;
  }

  .btn-quaternary {
    @apply bg-white text-base font-bold text-primary drop-shadow-primary hover:bg-accent-primary_hover hover:text-white;
  }

  .h1 {
    @apply text-[40px] xl:text-[80px] font-bold leading-[1.1];
  }

  .h2 {
    @apply text-[40px] font-bold leading-tight;
  }

  .h3 {
    @apply text-[24px];
  }

  .lead {
    @apply text-lg text-secondary font-secondary font-medium leading-relaxed;
  }
}


/* style for scrollbar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(59, 174, 167), rgb(156, 105, 226))
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(rgb(62, 177, 170), rgb(159, 108, 229))
}